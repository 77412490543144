import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat.js'

import _isEmpty from 'underscore/cjs/isEmpty.js'
import _isFunction from 'underscore/cjs/isFunction.js'
import _omit from 'underscore/cjs/omit.js'

import { validateAge } from '~/helpers/validator'

dayjs.extend(customParseFormat)

export const generateFormModel = ({ type, form, exceptionArr = [], url } = {}) => {
  type = type || 'default'

  if (!['default', 'validation', 'summary'].includes(type)) {
    // eslint-disable-next-line no-console
    console.error(`${type} is not supported!`)

    return {}
  }

  const model = {}

  if (isObject(form)) {
    for (const step in form) {
      if (Object.hasOwnProperty.call(form, step)) {
        const stepForm = form[step].form

        model[step] = type === 'summary' && url
          ? {
            url: url ? `${url}/${step}/` : undefined
          }
          : {}

        for (const formItemKey in stepForm) {
          if (Object.hasOwnProperty.call(stepForm, formItemKey)) {
            const key = strToSnakeCase(formItemKey)

            if (type === 'default') {
              if (form[step].form[formItemKey].component === undefined && form[step].form[formItemKey].components instanceof Array) {
                form[step].form[formItemKey].props.forEach(prop => {
                  model[step][strToSnakeCase(prop.name)] = exceptionArr.includes(strToSnakeCase(prop.name))
                    ? []
                    : ''
                })
              } else {
                model[step][key] = exceptionArr.includes(key)
                  ? []
                  : ''
              }
            } else if (type === 'validation') {
              if (
                form[step].form[formItemKey].component === undefined &&
                form[step].form[formItemKey].components instanceof Array
              ) {
                form[step].form[formItemKey].props.forEach(prop => {
                  model[step][strToSnakeCase(prop.name)] = {
                    success: {
                      status: false
                    },
                    error: {
                      status: false,
                      message: ''
                    }
                  }
                })
              } else {
                model[step][key] = {
                  success: {
                    status: false
                  },
                  error: {
                    status: false,
                    message: ''
                  }
                }
              }
            } else if (type === 'summary') {
              model[step][key] = {
                data: '',
                icon: '',
                label: ''
              }
            }
          }
        }
      }
    }
  } else if (isArray(form)) {
    for (let i = 0; i < form.length; i++) {
      const key = strToSnakeCase(form[i])

      if (type === 'default') {
        model[key] = exceptionArr.includes(key)
          ? []
          : ''
      } else if (type === 'validation') {
        model[key] = {
          success: {
            status: false
          },
          error: {
            status: false,
            message: ''
          }
        }
      } else if (type === 'summary') {
        model[key] = {
          data: '',
          icon: '',
          label: ''
        }
      }
    }
  }

  return model
}

// TODO: remove replaced with GENRATE_FORM_MODEL_TEST
export const GENERATE_FORM_MODEL = ({ type = 'default', form, exceptionArr = [], url } = {}) => {
  if (!['default', 'validation', 'summary'].includes(type)) {
    // eslint-disable-next-line no-console
    console.error(`${type} is not supported!`)

    return {}
  }

  const generateKeyValue = key => {
    if (type === 'summary') {
      return {
        data: '',
        icon: '',
        label: ''
      }
    }

    if (type === 'validation') {
      return {
        isSuccess: false,
        errorMessage: ''
      }
    }

    return exceptionArr.includes(key)
      ? []
      : ''
  }

  const generateModelFromObj = (obj, parent, newObj = {}) => {
    if (!parent) {
      for (const key in obj) {
        if (Object.hasOwnProperty.call(obj, key)) {
          const item = obj[key]?.form ?? obj[key]

          newObj[key] = type === 'summary' && url
            ? {
              url: (url && `${url}/${key}`) || undefined
            }
            : generateKeyValue(key)

          if (isObject(item)) {
          /**
           * calls the function again
           * if item is object
           */
            generateModelFromObj(item, key, newObj)
          }
        }
      }
    } else {
      for (const key in obj) {
        if (Object.hasOwnProperty.call(obj, key)) {
          const item = obj[key]

          if (
            item?.components instanceof Array &&
            item?.component === undefined
          ) {
            /**
             * handling for items that has
             * multiple components
             */
            item.props.forEach(prop => {
              newObj[parent][strToSnakeCase(prop.name)] = generateKeyValue(key)
            })
          } else {
            newObj[parent] = {
              ...newObj[parent],
              [strToSnakeCase(key)]: generateKeyValue(key)
            }
          }
        }
      }
    }

    return newObj
  }

  if (isObject(form)) {
    return generateModelFromObj(form)
  }

  if (isArray(form)) {
    const model = {}

    form.forEach(item => {
      const key = item?.props?.name ?? item

      model[strToSnakeCase(key)] = generateKeyValue(key)
    })

    return model
  }

  return {}
}

export const GENERATE_FORM_MODEL_TEST = ({
  type = 'default',
  form,
  formItems,
  exceptionArr = []
} = {}) => {
  if (!['default', 'validation'].includes(type)) {
    // eslint-disable-next-line no-console
    console.error(`${type} is not supported!`)

    return {}
  }

  const generateKeyValue = key => {
    if (type === 'summary') {
      return {
        data: '',
        icon: '',
        label: ''
      }
    }

    if (type === 'validation') {
      return {
        isSuccess: false,
        errorMessage: ''
      }
    }

    return exceptionArr.includes(strToSnakeCase(key))
      ? []
      : ''
  }

  const generateModelFromObj = (obj, newObj = {}) => {
    for (const key in obj) {
      if (Object.hasOwnProperty.call(obj, key)) {
        const item = obj[key]
        const step = (formItems && getKey(strToSnakeCase(key), formItems)) || null

        if (item?.components instanceof Array) {
          item.components.forEach(data => {
            const newKey = item?.components.length === 1
              ? strToSnakeCase(key)
              : strToSnakeCase(data.props?.name || key)

            if (step) {
              newObj[step] = {
                ...newObj[step],
                [newKey]: generateKeyValue(key)
              }
            } else {
              newObj[newKey] = generateKeyValue(key)
            }
          })
        }
      }
    }

    return newObj
  }

  if (isObject(form)) {
    return generateModelFromObj(form)
  }

  if (isArray(form)) {
    const model = form.reduce((acc, curr) => {
      const step = (formItems && getKey(strToSnakeCase(curr), formItems)) || null

      if (step) {
        return Object.assign(acc, {
          [step]: {
            ...acc[step],
            [strToSnakeCase(curr)]: generateKeyValue(curr)
          }
        })
      }

      return Object.assign(acc, {
        [strToSnakeCase(curr)]: generateKeyValue(curr)
      })
    }, {})

    return model
  }

  return {}
}

export const generateValidation = ({ type = '', message = '' }) => {
  if (!['error', 'success', ''].includes(type)) {
    // eslint-disable-next-line no-console
    return console.error('type doesn\'t exist')
  }

  if (!type) {
    return {
      isSuccess: false,
      errorMessage: ''
    }
  }

  if (type === 'error') {
    return {
      isSuccess: false,
      errorMessage: message
    }
  }

  return {
    isSuccess: true,
    errorMessage: ''
  }
}

export const getValidation = ({ step, key, validation, type } = {}) => {
  if (!key) {
    return // console.error('key is required!')
  }

  if (!validation) {
    return // console.error('validation is required!')
  }

  if (step && key) {
    if (validation && !validation?.[step] && !validation?.[step]?.[key]) {
      return
    }

    return {
      errorMessage: validation[step][key]?.errorMessage ?? '',
      isSuccess: validation[step][key]?.isSuccess ?? false
    }
  }

  if (!step && key) {
    if (type) {
      const tempType = type === 'success'
        ? 'isSuccess'
        : 'errorMessage'
      const tempValue = type === 'success'
        ? false
        : ''

      validation[key][tempType] = tempValue

      return
    }

    if (validation && !validation?.[key]) {
      return {
        errorMessage: validation[key]?.errorMessage ?? '',
        isSuccess: validation[key]?.isSuccess ?? false
      }
    }

    return {
      errorMessage: validation[key]?.errorMessage ?? '',
      isSuccess: validation[key]?.isSuccess ?? false
    }
  }
}

/**
 * generate validation error messages to form items
 */
export const setValidation = ({ step, form, validation } = {}) => {
  if (!step || !form || _isEmpty(form)) {
    return
  }

  const exceptionArr = [
    'MoleculesBirthdate',
    'AtomsDatepicker',
    'AtomsInput'
  ]

  for (const formKey in form) {
    if (Object.hasOwnProperty.call(form, formKey)) {
      const formItem = form[formKey]
      const validationParams = {
        step,
        key: strToSnakeCase(formKey),
        validation
      }

      if (formItem.props instanceof Array && formItem.components) {
        formItem.props.forEach((prop, index) => {
          const validationParamsArr = {
            step,
            key: formItem.props[index].name,
            validation
          }

          // modify __asyncResolved?.name
          if (!exceptionArr.includes(formItem.components[index])) {
            formItem.props[index].errorMessage = getValidation(validationParamsArr)?.error.message
          } else {
            validationParams.key = formItem.props[index].name

            formItem.props[index].error = getValidation(validationParamsArr)?.error
            formItem.props[index].success = getValidation(validationParamsArr)?.success
          }
        })
      } else if (!exceptionArr.includes(formItem.component)) {
        formItem.props.errorMessage = getValidation(validationParams)?.error.message
      } else {
        formItem.props.error = getValidation(validationParams)?.error
        formItem.props.success = getValidation(validationParams)?.success
      }
    }
  }
}

export const SET_VALIDATION = ({ step, form, validation } = {}) => {
  for (const formKey in form) {
    if (Object.hasOwnProperty.call(form, formKey)) {
      const formItem = form[formKey]

      if (formItem?.components instanceof Array) {
        formItem.components.forEach(data => {
          const newKey = formItem?.components.length === 1
            ? strToSnakeCase(formKey)
            : strToSnakeCase(data.props?.name || formKey)

          data.props = {
            ...data.props,
            ...validation[step][newKey]
          }
        })
      }
    }
  }
}

export const CHECK_FILLED_STEPS = (form, exceptionArr = []) => {
  if (!form) {
    // eslint-disable-next-line no-console
    console.log('%cCHECK_FILLED_STEPS: FORM is required.', 'color: red')
    return false
  }

  const formSteps = {}

  // remove non step keys
  form = _omit(form, value => !isObject(value))

  for (const formKey in form) {
    if (Object.hasOwnProperty.call(form, formKey)) {
      const curr = _omit(form[formKey], (_, key) => exceptionArr.includes(key))

      const currValues = !_isEmpty(Object.values(curr))
        ? Object.values(curr)
          .map(item => {
            if (typeof item === 'object' || typeof item === 'string') {
              return !_isEmpty(item)
            }
            
            return item
          })
          .every(item => item)
        : false
    
      formSteps[formKey] = currValues
    }
  }

  const filledSteps = Object.keys(formSteps)
    .filter(key => formSteps[key])

  return !_isEmpty(filledSteps)
}

/**
 * checks if previous steps has value
 * if not, then it will redirect back to initial step
 */
export const VALIDATE_PREVIOUS_STEPS = ({ form, step, exceptionArr = [], url, initialStep = 'step1' } = {}) => {
  if (!url) {
    // eslint-disable-next-line no-console
    return console.log('URL is required!')
  }

  if (step < 2) {
    return true
  }

  const prevStepsFilled = CHECK_FILLED_STEPS(form, exceptionArr)

  if (!prevStepsFilled) {
    // eslint-disable-next-line no-console
    console.error(`It can't proceed to the next step because there values not filled in ${initialStep}.`)

    navigateTo(trailingSlash(`${url}/${initialStep}`), {
      redirectCode: 301
    })
  }
}

export const validateForm = ({ step, form, errorMap, validation, externalValidation = undefined, exceptionArr = [] } = {}) => {
  if (!form && !errorMap && !validation) {
    return
  }

  let formModel = JSON.parse(JSON.stringify(form))

  if (step) {
    formModel = _omit(getKey(step, formModel), function (value, key) {
      return exceptionArr.includes(key)
    })
  }

  for (const key in formModel) {
    if (Object.hasOwnProperty.call(formModel, key)) {
      const curr = formModel[key]
      const obj = {
        type: !_isEmpty(curr) ? 'success' : 'error',
        message: !_isEmpty(curr) ? '' : (errorMap?.[key] ?? '')
      }

      if (validation?.[step]?.[key] || validation?.[key]) {
        if (step) {
          validation[step][key] = generateValidation(obj)
        } else {
          validation[key] = generateValidation(obj)
        }
      }
    }
  }

  /**
   * overwrites initial validation where it checks if value is empty
   */
  if (_isFunction(externalValidation)) {
    for (const key in formModel) {
      if (Object.hasOwnProperty.call(formModel, key)) {
        if (externalValidation) {
          externalValidation({
            formModel,
            key,
            currentStep: step
          })
        }
      }
    }
  }
}

export function VALIDATE_FORM_ITEM ({
  key,
  value,
  errorMap,
  _function = undefined,
  validation,
  validationType,
  validationMsg
}) {
  const typeValue = _function?.(key, value)?.[validationType] ??
    _function?.(key, value) ??
    value

  const message = (_function?.(key, value)?.[validationMsg] ??
    _function?.(key, value)) ||
    errorMap?.[key] ||
    ''

  validation.value[key] = generateValidation({
    type: (typeValue?.valid ?? typeValue)
      ? 'success'
      : 'error',
    message: message.errorMessage ?? message
  })
}

export function VALIDATE_FORM ({
  currentStep = undefined,
  form,
  errorMap,
  validation,
  externalValidation = undefined,
  exceptionArr = []
} = {}) {
  if (_isEmpty(form.value)) {
    // eslint-disable-next-line no-console
    return console.error('Form object is required!')
  }

  if (_isEmpty(validation)) {
    // eslint-disable-next-line no-console
    return console.error('Validation object is required!')
  }

  if (_isEmpty(errorMap)) {
    // eslint-disable-next-line no-console
    return console.error('Error map is required!')
  }

  let formModel = JSON.parse(JSON.stringify(form.value))

  if (currentStep && !_isEmpty(exceptionArr)) {
    formModel = _omit(getKey(currentStep, formModel), function (value, key) {
      return exceptionArr.includes(key)
    })
  }

  for (const key in formModel) {
    if (Object.hasOwnProperty.call(formModel, key)) {
      const curr = formModel[key]
      const checkValue = (isObject(curr) || isArray(curr))
        ? !_isEmpty(curr)
        : curr

      const obj = {
        type: checkValue ? 'success' : 'error',
        message: checkValue ? '' : (errorMap?.[key] ?? '')
      }

      if (
        validation.value?.[currentStep]?.key ||
        validation.value?.[key]
      ) {
        if (currentStep) {
          validation.value[currentStep][key] = {
            ...validation.value[currentStep][key],
            ...generateValidation(obj)
          }
        } else {
          validation.value[key] = {
            ...validation.value[key],
            ...generateValidation(obj)
          }
        }
      }
    }
  }

  /**
   * overwrites initial validation
   * where it only checks if value is not empty
   */
  if (
    externalValidation &&
    _isFunction(externalValidation)
  ) {
    for (const key in formModel) {
      if (Object.hasOwnProperty.call(formModel, key)) {
        externalValidation({
          model: formModel,
          key,
          value: formModel?.[key],
          currentStep
        })
      }
    }
  }
}

export const validateFormItem = ({
  key,
  value,
  errorMap,
  validateFunction = undefined,
  validation,
  validationType,
  validationMsg
} = {}) => {
  if (!key || (value === null || value === undefined)) {
    // eslint-disable-next-line no-console
    return console.error('key, and value is required.')
  }

  if (!_isFunction(validateFunction)) {
    // eslint-disable-next-line no-console
    return console.error('validate function is required.')
  }

  if (_isEmpty(errorMap)) {
    // eslint-disable-next-line no-console
    return console.error('errorMap is required.')
  }

  if (_isEmpty(validation)) {
    // eslint-disable-next-line no-console
    return console.error('errorMap is required.')
  }

  if (!validationType && !validationMsg) {
    validation[key] = generateValidation({
      type: validateFunction(key, value) ? 'success' : 'error',
      message: validateFunction(key, value) ? '' : (errorMap?.[key] ?? '')
    })
  } else {
    validation[key] = generateValidation({
      type: validateFunction(key, value)?.[validationType]
        ? 'success'
        : 'error',
      message: validateFunction(key, value)?.[validationMsg] ?? ''
    })
  }
}

export const handleScrollToError = obj => {
  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      const item = obj[key]

      if (item?.errorMessage && !item?.isSuccess) {
        const el = document.querySelector(`.${strToKebabCase(key)}`)
        const offset = 300
        const targetPos = el?.offsetTop
          ? el.offsetTop - offset
          : 0

        window.scrollTo(0, targetPos)

        // eslint-disable-next-line no-console
        console.error(`validation error ${key}`)
        return `validation error ${key}`
      }
    }
  }

  return false
}

export const handleValidateAge = ({ age, key, step, formModel, validation, validAge, errorMap } = {}) => {
  try {
    age = age ?? 0

    if (!key) {
      // eslint-disable-next-line no-console
      return console.log('key is required!')
    }

    if (!step) {
      // eslint-disable-next-line no-console
      return console.log('step is required!')
    }

    if (!validAge) {
      // eslint-disable-next-line no-console
      return console.log('valid age array is required!')
    }

    if (_isEmpty(formModel)) {
      // eslint-disable-next-line no-console
      return console.log('formModel is required!')
    }

    if (_isEmpty(validation)) {
      // eslint-disable-next-line no-console
      return console.log('validation is required!')
    }

    if (_isEmpty(errorMap)) {
      // eslint-disable-next-line no-console
      return console.log('errorMap is required!')
    }

    let keyName = key

    if (['birthdate', 'date_of_birth', 'partner_birthdate'].includes(key)) {
      keyName = ['birthdate', 'date_of_birth'].includes(key)
        ? 'age'
        : 'age_secondary'
    }

    const isAgeValid = validateAge(age, validAge)

    validation[step][key] = generateValidation({
      type: isAgeValid ? 'success' : 'error',
      message: isAgeValid ? '' : (errorMap[key] ?? '')
    })

    if (['birthdate', 'date_of_birth', 'partner_birthdate'].includes(key)) {
      formModel[step][keyName] = age
    }

    return isAgeValid
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }
}

// TODO: delete
export const handleValidateRange = ({ value, key, step, formModel, validation, validRange, errorMap } = {}) => {
  try {
    value = value ?? 0

    if (!key) {
      // eslint-disable-next-line no-console
      return console.log('key is required!')
    }

    if (!step) {
      // eslint-disable-next-line no-console
      return console.log('step is required!')
    }

    if (!validRange) {
      // eslint-disable-next-line no-console
      return console.log('valid range array is required!')
    }

    if (_isEmpty(formModel)) {
      // eslint-disable-next-line no-console
      return console.log('formModel is required!')
    }

    if (_isEmpty(validation)) {
      // eslint-disable-next-line no-console
      return console.log('validation is required!')
    }

    if (_isEmpty(errorMap)) {
      // eslint-disable-next-line no-console
      return console.log('errorMap is required!')
    }

    // formModel[step][key] = value

    const isRangeValid = validateAge(value, validRange)

    validation[step][key] = generateValidation({
      type: isRangeValid ? 'success' : 'error',
      message: isRangeValid ? '' : (errorMap[key] ?? '')
    })

    return isRangeValid
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }
}

export const handleValidatePostcode = ({ isSuccess, errorMessage, key, step, errorMap, validation } = {}) => {
  if (_isEmpty(validation)) {
    // eslint-disable-next-line no-console
    console.log('validation obj is required!')
  }

  key = key || 'postcode'
  errorMessage = errorMap?.[key] || errorMessage

  if (step) {
    validation[step][key] = generateValidation({
      type: isSuccess ? 'success' : 'error',
      message: isSuccess
        ? ''
        : errorMessage
    })
  } else {
    validation[key] = generateValidation({
      type: isSuccess ? 'success' : 'error',
      message: isSuccess
        ? ''
        : errorMessage
    })
  }
}

export const handleCountryValidation = ({ value, step, key, errorMap, validation } = {}) => {
  try {
    if (!step) {
      // eslint-disable-next-line no-console
      console.log('step is required!')
    }

    if (!key) {
      // eslint-disable-next-line no-console
      console.log('key is required!')
    }

    if (_isEmpty(errorMap)) {
      // eslint-disable-next-line no-console
      console.log('errorMap is required!')
    }

    if (_isEmpty(validation)) {
      // eslint-disable-next-line no-console
      console.log('validation is required!')
    }

    value = value ?? validation?.[step]?.[key] ?? 'error'

    validation[step][key] = generateValidation({
      type: value,
      message: value === 'success' ? '' : (errorMap?.[key] ?? '')
    })
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }
}

export const handleDisablePastDate = date => {
  try {
    if (!date) {
      return
    }

    date = dayjs(date, 'DD/MM/YYYY')

    const now = dayjs()
    const days = Math.floor(date.diff(dayjs(now), 'day', true))

    const isValid = days >= -1

    return isValid
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }
}

export const handleValidatePercentage = ({ key, step, form, errorMap, validation } = {}) => {
  try {
    key = key ?? 'interest_rate'

    if (!key) {
      // eslint-disable-next-line no-console
      return console.log('key is required!')
    }

    if (!step) {
      // eslint-disable-next-line no-console
      return console.log('step is required!')
    }

    if (_isEmpty(form)) {
      // eslint-disable-next-line no-console
      return console.log('form is required!')
    }

    if (_isEmpty(validation)) {
      // eslint-disable-next-line no-console
      return console.log('validation is required!')
    }

    if (_isEmpty(errorMap)) {
      // eslint-disable-next-line no-console
      return console.log('errorMap is required!')
    }

    const value = (form[step][key]).replace(/%/g, '') ?? 0

    validation[step][key] = generateValidation({
      type: (value && value <= 100) ? 'success' : 'error',
      message: (value && value <= 100) ? '' : (errorMap[key] ?? '')
    })
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }
}
